@import "../../assets/css/variables.scss";

.ai6ix-page-history {
    &-tabs {
        margin: 0px !important;
    }

    &-content {
        &-row {
            display: flex;
            gap: 12px;
            flex-direction: column;

            &-box {
                display: flex;
                padding: 12px;
                align-items: center;
                gap: 12px;
                @include vendor-prefix(border-radius, 6px);
                background-color: var(--hms-ui-colors-surface_bright);
                justify-content: space-between;
                cursor: pointer;

                &-text {
                    color: var(--hms-ui-colors-textPrimary);
                    font-size: 16px;
                    line-height: 150%;
                    font-weight: 500;
                }

                &-action {
                    display: flex;
                    gap: 5px;
                    cursor: auto;

                    &-btn {
                        @include vendor-prefix(border-radius, 8px !important);

                        &:hover,
                        &.active {
                            background-color: var(--hms-ui-colors-surfaceLighter) !important;
                            color: var(--hms-ui-colors-white) !important;

                            svg path {
                                &[stroke] {
                                    stroke: var(--hms-ui-colors-white);
                                }

                                &[fill] {
                                    fill: var(--hms-ui-colors-white);
                                }
                            }
                        }
                    }
                }

                &:hover & {
                    &-text {
                        color: var(--hms-ui-colors-yellow);
                    }
                }
            }
        }

        &-empty {
            padding: 20px 0px;
            text-align: center;
            color: var(--hms-ui-colors-secondary);
            width: 100%;
        }
    }
}