@import "../../assets/css/variables.scss";

.ai6ix-layout {
    &-footer {
        position: relative;
        padding: 48px 24px 48px;

        @media (max-width: 767px) {
            padding: 48px 16px;
        }

        &-top {
            gap: 10px;
            justify-content: space-between;
            display: flex;

            &-logo {
                width: 42px;

                img {
                    max-width: 100%;
                }
            }

            &-social {
                align-items: center;
                display: flex;

                &-text {
                    color: var(--hms-ui-colors-light);
                    font-size: 14px;
                    margin-right: 20px;

                    @media (max-width: 767px) {
                        font-size: 13px;
                        margin-right: 15px;
                    }
                }

                &-link {
                    cursor: pointer;
                    line-height: 24px;
                    padding: 0px 5px;
                    color: var(--hms-ui-colors-light);

                    &:last-child {
                        padding-right: 0px;
                    }

                    &:hover {
                        color: var(--hms-ui-colors-white);
                    }
                }
            }
        }

        &-divider {
            width: 100%;
            height: 1px;
            background-color: var(--hms-ui-colors-borderDefault);
            margin: 36px 0px;

            @media (max-width: 1499px) {
                margin: 24px 0px;
            }
        }

        &-access {
            width: 100%;

            &-heading {
                color: var(--hms-ui-colors-white);
                text-align: center;
                font-size: 26px;
                font-weight: 700;

                @media (max-width: 1499px) {
                    font-size: 22px;
                }

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }

            &-text {
                color: var(--hms-ui-colors-secondary);
                text-align: center;
                font-size: 14px;

                @media (max-width: 1199px) {
                    font-size: 13px;
                }
            }

            &-row {
                margin-top: 36px;
                gap: 12px;
                display: flex;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                &-box {
                    padding: 10px;
                    background-color: var(--hms-ui-colors-mainBg);
                    font-size: 14px;
                    color: var(--hms-ui-colors-light);
                    @include vendor-prefix(border-radius, 8px);
                    flex: 1;
                    gap: 5px;
                    align-items: center;
                    display: flex;

                    @media (max-width: 1199px) {
                        font-size: 13px;
                    }

                    span {
                        background: #7a6d40;
                        @include vendor-prefix(border-radius, 4px);
                        padding: 3px 6px;
                    }
                }
            }
        }

        &-menu {
            gap: 48px;
            align-items: center;
            justify-content: center;
            display: flex;

            @media (max-width: 1199px) {
                gap: 36px;
            }

            @media (max-width: 767px) {
                gap: 20px;
            }

            &-link {
                color: var(--hms-ui-colors-textPrimary);
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;

                @media (max-width: 767px) {
                    font-size: 13px;
                }

                &:hover {
                    color: var(--hms-ui-colors-yellow);
                }
            }
        }

        &-cmsmenu {
            align-items: center;
            justify-content: end;
            margin-top: auto;
            display: flex;

            &-inner {
                gap: 36px;
                align-items: center;
                display: flex;
            }

            &-link {
                position: relative;

                &-btn {
                    color: var(--hms-ui-colors-light);
                    font-size: 14px;
                    position: relative;
                    align-items: center;
                    display: flex;
                    white-space: nowrap;
                    cursor: pointer;

                    span {
                        text-transform: uppercase;
                        margin-left: 8px;
                    }

                    &.active,
                    &:hover {
                        color: var(--hms-ui-colors-yellow);

                        svg path {
                            &[fill] {
                                fill: var(--hms-ui-colors-yellow);
                            }

                            &[stroke] {
                                stroke: var(--hms-ui-colors-yellow);
                            }
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid var(--hms-ui-colors-borderDefault);
                        background: var(--hms-ui-colors-mainBg);
                        box-shadow: 0px 4px 50px 0px var(--hms-ui-colors-surfaceLight);
                        min-width: 200px;
                        max-width: 90%;
                    }

                    &-language {
                        &-header {
                            color: var(--hms-ui-colors-gray);
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                            max-height: 70%;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        &-item {
                            color: var(--hms-ui-colors-light) !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: var(--hms-ui-colors-yellow) !important;
                            }

                            @media (max-width: 767px) {
                                font-size: 13px !important;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1024px) {
                justify-content: center;
                margin-top: 20px;

                &-inner {
                    gap: 16px;
                }

                &-link {
                    &-btn {
                        font-size: 13px;
                    }
                }
            }
        }

        &-content {
            width: 100%;
            display: flex;

            @media (max-width: 1199px) {
                flex-direction: column;
            }

            &-space {
                margin-bottom: 20px;

                @media (max-width: 1499px) {
                    margin-bottom: 16px;
                }
            }

            &-left {
                padding-right: 24px;
                flex: 1;
                gap: 24px;
                flex-direction: column;
                display: flex;

                @media (max-width: 1199px) {
                    padding-right: 0px;
                }

                &-row {
                    gap: 24px;
                    display: flex;

                    @media (max-width: 1366px) {
                        gap: 16px;
                    }

                    @media (max-width: 767px) {
                        flex-direction: column;
                    }

                    &-content {
                        width: 44%;

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        &-heading {
                            color: var(--hms-ui-colors-yellow);
                            font-size: 16px;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 16px;

                            @media (max-width: 1499px) {
                                font-size: 14px;
                            }

                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }
                        }

                        &-text {
                            color: var(--hms-ui-colors-light);
                            font-size: 14px;

                            @media (max-width: 1199px) {
                                font-size: 13px;
                            }

                            ul {
                                list-style-type: disc;
                                padding-left: 20px;

                                li {
                                    margin-bottom: 2px;
                                }
                            }
                        }
                    }

                    &-menu {
                        flex: 1;
                        gap: 20px;
                        display: flex;

                        @media (max-width: 1499px) {
                            gap: 16px;
                        }

                        @media (max-width: 767px) {
                            flex-direction: column;
                        }

                        &-box {
                            flex-direction: column;
                            flex: 1;
                            display: flex;

                            &-heading {
                                color: var(--hms-ui-colors-textPrimary);
                                font-size: 16px;
                                font-weight: 700;
                                text-transform: uppercase;
                                margin-bottom: 16px;

                                @media (max-width: 1499px) {
                                    font-size: 14px;
                                }

                                @media (max-width: 767px) {
                                    margin-bottom: 10px;
                                }
                            }

                            &-link {
                                color: var(--hms-ui-colors-light);
                                font-size: 14px;
                                margin-bottom: 10px;

                                @media (max-width: 1199px) {
                                    font-size: 13px;
                                }

                                @media (max-width: 767px) {
                                    margin-bottom: 5px;
                                }

                                &:hover {
                                    color: var(--hms-ui-colors-yellow);
                                }
                            }
                        }
                    }
                }
            }

            &-right {
                width: 30%;
                gap: 48px;
                flex-direction: column;
                display: flex;

                @media (max-width: 1499px) {
                    gap: 30px;
                }

                @media (max-width: 1199px) {
                    width: 100%;
                    margin-top: 24px;
                }

                &-box {
                    &-heading {
                        color: var(--hms-ui-colors-textPrimary);
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 16px;

                        @media (max-width: 1499px) {
                            font-size: 14px;
                        }

                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                    }

                    &-text {
                        color: var(--hms-ui-colors-light);
                        font-size: 14px;

                        @media (max-width: 1199px) {
                            font-size: 13px;
                        }

                        &-sm {
                            font-size: 9px;
                        }
                    }
                }
            }
        }

        &-bottom {
            padding-top: 36px;

            &-text {
                color: var(--hms-ui-colors-yellow);
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;

                @media (max-width: 1499px) {
                    font-size: 14px;
                }
            }
        }
    }
}