@mixin vendor-prefix($property, $value) {
    #{$property}: $value;

    @each $prefix in("webkit", "moz", "ms", "o") {
        #{'-' + $prefix + '-' + $property}: $value;
    }
}

@mixin vendor-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}