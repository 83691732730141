@import "../../assets/css/variables.scss";

.ai6ix-page-chat {
    height: calc(100vh - 80px);

    &-content {
        height: calc(100% - 80px);
        // display: flex;
        // flex-direction: column;

        &-area {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            &-middle {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
            }

            &-response {
                flex: 1;
                overflow-y: auto;

                &-row {
                    width: 100%;
                }

                &-box {
                    display: flex;
                    gap: 12px;
                    padding: 12px 12px;
                    width: 100%;
                    margin-bottom: 10px;

                    &-question {
                        color: var(--hms-ui-colors-textPrimary);
                        background: var(--hms-ui-colors-surface_bright);
                        @include vendor-prefix(border-radius, 11px);
                        align-items: center;
                        white-space: pre-wrap;

                        &-logo {
                            background: var(--hms-ui-colors-borderDefault);
                            @include vendor-prefix(border-radius, 50%);
                            height: 30px;
                            width: 30px;
                            display: flex;
                            align-items: center;

                            img {
                                @include vendor-prefix(border-radius, 50%);
                                object-fit: cover;
                                width: 100%;
                            }

                            svg {
                                margin: auto;
                            }
                        }
                    }

                    &-answer {
                        color: var(--hms-ui-colors-light);

                        &-logo {
                            height: 30px;
                            width: 30px;

                            img {
                                width: 100%;
                            }
                        }

                        &-content {
                            display: flex;
                            flex: 1 1;
                            justify-content: space-between;
                            max-width: 100%;
                            position: relative;

                            &-row {
                                flex: 1 1;
                                text-align: left;

                                > :first-child {
                                    margin-top: 0px;
                                }

                                p {
                                    margin-bottom: 15px;
                                }
                            }

                            &-action {
                                display: flex;
                                margin: 0 0 auto 10px;

                                &-menu {
                                    &-list {
                                        font-size: 14px !important;
                                        padding: 10px 20px !important;
                                        color: var(--hms-ui-colors-textPrimary) !important;

                                        svg {
                                            margin-right: 10px;
                                            width: 20px;
                                            height: auto;

                                            path {
                                                &[stroke] {
                                                    stroke: var(--hms-ui-colors-light);
                                                }

                                                &[fill] {
                                                    fill: var(--hms-ui-colors-light);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }

                }
            }
        }


        &-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;

            &-img {
                height: 60px;
            }
        }

        &-searcharea {
            width: 100%;
            position: relative;
            padding: 20px 0px;
            background-color: var(--hms-ui-colors-mainBg);

            &-top {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                margin-bottom: 15px;

                &-login {
                    align-items: center;
                    border: 1px solid var(--hms-ui-colors-borderDefault);
                    @include vendor-prefix(border-radius, 6px);
                    display: flex;
                    justify-content: space-between;
                    margin: 0 auto;
                    max-width: 574px;
                    padding: 8px;
                    width: 100%;
                    font-size: 14px;

                    &-text {
                        color: var(--hms-ui-colors-secondary);
                        line-height: 145%;
                        margin: 0 10px 0 0;
                    }
                }
            }

            &-box {
                align-items: center;
                background-color: var(--hms-ui-colors-backgroundDefault);
                border: 1px solid var(--hms-ui-colors-borderDefault);
                @include vendor-prefix(border-radius, 6px);
                display: flex;
                gap: 24px;
                align-items: center;
                //height: 48px;
                margin: 0 auto;
                max-width: 885px;
                padding: 12px 16px;
                position: relative;
                width: 100%;

                &-sendimg {
                    right: 0px;
                    display: flex;
                    align-items: center;
                    top: 0;
                    bottom: 0;
                    padding: 0px 10px;
                    position: absolute;
                    cursor: pointer;
                    opacity: 0.6;

                    &:hover {
                        opacity: 1;
                    }
                }

                &-input {
                    padding-right: 35px;
                    padding-left: 35px;
                    width: 100%;
                    background: transparent;
                    outline: none;
                    border: none;
                    color: var(--hms-ui-colors-white);
                    resize: none;


                    &-withSelect {
                        padding-left: 110px;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #f1f1f100;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #414141;
                        border-radius: 10px;
                    }

                    &-select {
                        background: transparent;
                        outline: none;
                        border: none;
                        color: #fff;
                        resize: none;
                        left: 45px;
                        width: 70px;
                        position: absolute !important;
                        outline: none !important;
                        font-size: 90%;
                        color: #8c8e95;
                    }
                }

                &-attachment {
                    color: #fff;
                    height: auto;
                    width: 35px;
                    text-align: center;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: 5px;
                    top: 0;
                    bottom: 0;
                    font-size: 20px;
                    border: none;
                    background: transparent;
                    cursor: pointer;

                    img {
                        max-width: 100%;
                        width: 20px;
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }

                    &-input {
                        display: none;
                    }
                }

                &-creating {
                    position: absolute;
                    background: var(--hms-ui-colors-surface_bright);
                    @include vendor-prefix(border-radius, 6px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    color: var(--hms-ui-colors-light);
                    z-index: 5;

                    &-msg {
                        font-size: 16px;
                        line-height: 150%;
                    }
                }
            }

            &-bottom {
                text-align: center;
                margin-top: 10px;
                font-size: 13px;
                color: rgb(255 255 255 / 90%);
            }

        }
    }
}