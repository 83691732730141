@import "../../assets/css/variables.scss";

.ai6ix-page-landing {


    &-section {
        width: 100%;
        position: relative;

        &-space {
            padding-top: 70px;
            padding-bottom: 70px;

            @media (max-width: 767px) {
                padding-top: 55px;
                padding-bottom: 55px;
            }

            &-small {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            &-top {
                padding-top: 70px;

                @media (max-width: 767px) {
                    padding-top: 55px;
                }
            }

            &-bottom {
                padding-bottom: 70px;

                @media (max-width: 767px) {
                    padding-bottom: 55px;
                }
            }
        }

        &-inner {
            max-width: 1130px;
            padding: 0px 16px;
            min-height: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        &-header {
            width: 100%;

            &-tag {
                color: var(--hms-ui-colors-yellow);
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 8px;
            }

            &-heading {
                color: var(--hms-ui-colors-textPrimary);
                font-size: 48px;
                font-weight: 900;
                line-height: 115%;
                font-family: var(--hms-ui-fonts-heading);

                @media (max-width: 1024px) {
                    font-size: 44px;
                }

                @media (max-width: 767px) {
                    font-size: 30px;
                }
            }

            &-text {
                color: var(--hms-ui-colors-light);
                font-size: 18px;
                margin-top: 12px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }

        &-content {
            padding-top: 36px;
        }

        &-fill {
            background-color: var(--hms-ui-colors-primaryLight);
        }

        &-line {
            padding-top: 65px;
            padding-bottom: 65px;

            @media (max-width: 767px) {
                padding-top: 55px;
                padding-bottom: 55px;
            }

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    &-intro {
        &-content {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            position: relative;
            padding-top: 20px;
            padding-bottom: 70px;

            &-heading {
                font-size: 60px;
                line-height: 75px;
                margin-bottom: 20px;
                color: var(--hms-ui-colors-white);
                font-weight: 900;
                font-family: var(--hms-ui-fonts-heading);

                @media (max-width: 1024px) {
                    font-size: 54px;
                    line-height: 68px;
                }

                @media (max-width: 767px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }

            &-text {
                font-size: 22px;
                font-weight: 700;
                color: var(--hms-ui-colors-light);
                margin-bottom: 35px;

                @media (max-width: 1024px) {
                    font-size: 20px;


                }

                @media (max-width: 767px) {
                    font-size: 16px;
                    font-weight: 400;

                    br {
                        display: none;
                    }
                }
            }

            &-action {
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: center;
            }
        }

        &-video {
            width: 100%;
            position: relative;

            iframe {
                width: 100%;
                height: 619px;
            }
        }
    }

    &-howWork {
        &-content {
            display: flex;
            gap: 24px;
            margin-top: 30px;

            @media (max-width: 767px) {
                flex-direction: column;
            }

            &-item {
                width: 25%;
                border: 1px solid var(--hms-ui-colors-borderDefault2);
                background-color: var(--hms-ui-colors-surface_default);
                @include vendor-prefix(border-radius, 11px);
                padding: 24px;
                @include vendor-prefix(backdrop-filter, blur(50px));

                @media (max-width: 767px) {
                    width: 100%;
                    gap: 24px;
                }

                &-title {
                    margin-bottom: 24px;

                    svg {
                        width: 24px;
                        height: auto;

                        path {
                            &[fill] {
                                fill: var(--hms-ui-colors-yellow);
                            }

                            &[stroke] {
                                stroke: var(--hms-ui-colors-yellow);
                            }
                        }
                    }
                }

                &-text {
                    font-size: 16px;
                    color: var(--hms-ui-colors-textPrimary);
                    font-weight: 700;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &-platform {
        &-content {
            border: 1px solid var(--hms-ui-colors-background_default);
            background-color: var(--hms-ui-colors-background_default);
            @include vendor-prefix(border-radius, 11px);
            padding: 32px;
            border: 1px solid rgba(74, 74, 74, 0.5);
            backdrop-filter: blur(50px);

            @media (max-width: 767px) {
                padding: 24px;
            }

            &-title {
                font-size: 48px;
                font-weight: 900;
                line-height: 150%;
                color: var(--hms-ui-colors-textPrimary);
                margin-bottom: 30px;

                @media (max-width: 1024px) {
                    font-size: 44px;
                }

                @media (max-width: 767px) {
                    font-size: 36px;
                    line-height: 125%;
                }
            }

            &-row {
                width: 100%;
                border-top: 1px solid var(--hms-ui-colors-borderDefault2);
                padding: 40px 0px;
                display: flex;
                gap: 59px;

                &:last-child {
                    padding-bottom: 10px;
                }

                @media (max-width: 767px) {
                    flex-direction: column;
                    gap: 18px;
                    padding: 24px 0px;
                }

                &-title {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 150%;
                    color: var(--hms-ui-colors-textPrimary);
                    display: flex;
                    width: 25%;
                    gap: 24px;
                    flex: none;
                    align-items: center;

                    svg {
                        width: 18px;
                        height: auto;
                        flex: none;

                        @media (max-width: 767px) {
                            width: 16px;
                        }
                    }

                    @media (max-width: 1024px) {
                        font-size: 22px;
                    }

                    @media (max-width: 767px) {
                        font-size: 16px;
                        width: 100%;
                        gap: 12px;
                    }
                }

                &-text {
                    font-size: 18px;
                    color: var(--hms-ui-colors-light);

                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

}