:root {
  --bs-body-font-family: "Roboto", sans-serif;
  --hms-ui-fonts-heading: "Playfair Display", serif;
  --hms-ui-colors-mainBg: #1F1F1F;
  --hms-ui-colors-yellow: #e3c050;
  --hms-ui-colors-secondary: #8c8e95;
  --hms-ui-colors-borderDefault: #373737;
  --hms-ui-colors-surface_bright: #2B2B2B;
  --hms-ui-colors-surface_dim: #1f1f1f;
  --hms-ui-colors-surfaceLighter: #454648;
  --hms-ui-colors-secondary_default: #454648;
  --hms-ui-colors-light: #C0C0C0;
  --hms-ui-colors-textPrimary: #e9e9e9;
  --hms-ui-colors-white: #fff;
  --hms-ui-colors-black: #000;
  --hms-ui-colors-gray: #575757;
  --hms-ui-colors-danger: #FB1751;
  --hms-ui-colors-dark: #4e4e4e;
  --hms-ui-colors-dark-light: #3f3f41;
  --hms-ui-colors-borderLighter: #373737;
  --hms-ui-colors-secondary_dim: #454648;
  --hms-ui-colors-primaryLightBorder: #454648;
  --hms-ui-colors-backgroundDefault: #131313;
  --hms-ui-colors-on_surface_high: #EDEDED;
  --hms-ui-colors-gray-gradient: linear-gradient(180deg,
      #1f1f1f 0%,
      rgba(31, 31, 31, 0.8) 100%);

}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--hms-ui-colors-mainBg);
  color: var(--hms-ui-colors-light);
}