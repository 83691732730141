@import "../../assets/css/variables.scss";

.ai6ix-layout-page {
    &-header {
        width: 100%;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
            padding: 16px 16px;
        }

        &-left {
            display: flex;
            align-items: center;
        }

        &-menu {
            overflow: auto;
            white-space: nowrap;
            border-bottom: 1px solid var(--hms-ui-colors-surface_bright);

            &-link {
                display: inline-block;
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                color: var(--hms-ui-colors-secondary);
                padding: 0px 8px 5px;
                line-height: 40px;
                text-transform: capitalize;
                border-bottom: 2px solid transparent;

                &:not(:first-child) {
                    margin-left: 12px;

                    @media (max-width: 767px) {
                        margin-left: 10px;
                    }
                }

                &.active {
                    color: var(--hms-ui-colors-textPrimary);
                    border-color: var(--hms-ui-colors-yellow);
                }

                &:hover {
                    color: var(--hms-ui-colors-textPrimary);
                }
            }
        }

        &-right {
            gap: 10px;
            display: flex;
            align-items: center;
        }
    }
}